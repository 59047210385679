/* This file is automatically generated. Do not edit. */
body {
  margin: 0;
  font-family: 'Montserrat Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #1f2226;  /* same as .app background color */
}

