/* If you can make it better, please do! */
.app {
    background-color: #1f2226;  /* greyish black */
    color: white;  /* white text */
    font-size: 20px;  /* larger text */
    text-align: center;
    font-family: 'Montserrat Variable', sans-serif;
}

.nav {
    background-color: #1f2226;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.button {
    background-color: #ae1f56;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 12px;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: capitalize;
}

.button:first-child {
    margin-left: 0;
}

.button:last-child {
    margin-right: 0;
}

/* Media query for screens wider than 768px */
@media (min-width: 768px) {
    .button {
        margin-left: 20px;
        margin-right: 20px;
    }

    .button:first-child {
        margin-left: 0;
    }

    .button:last-child {
        margin-right: 0;
    }
}

.button:hover {
    background-color: #f2aa66;
    color: black;
}

.aboutContainer {
    width: 40%;  /* default width for larger screens */
    margin: auto;
    background-color: rgb(39, 42, 47);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}

/* Media query for screens narrower than 768px */
@media (max-width: 768px) {
    .aboutContainer {
        width: 90%;  /* increased width for smaller screens */
    }
}

.homeContainer {
    width: 40%;  /* default width for larger screens */
    margin: auto;
    background-color: rgb(39, 42, 47);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    color: white;
}

/* Media query for screens narrower than 768px */
@media (max-width: 768px) {
    .homeContainer {
        width: 90%;  /* increased width for smaller screens */
    }
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.homeContainer, .aboutContainer, .linksContainer {
    /* ...existing styles... */
    animation: fadeInUp 700ms ease-out;
}

.linksContainer {
    width: 40%;
    margin: auto;
    background-color: rgb(39, 42, 47);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    color: #1c58c7;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linkButton {
    color: white;
    background-color: rgb(28, 88, 199);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px 0;
    text-decoration: none;
    text-align: center;
    width: 100%;
    max-width: 200px;
}

.linkButton:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

/* Media query for screens narrower than 768px */
@media (max-width: 768px) {
    .linksContainer {
        width: 90%;
    }
    .linkButton {
        width: 90%;
    }
}

